import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias21 from "../../../../static/img/blog/noticias-21-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location}
                title='Dos prácticas de relación con inversionistas que tomaron mayor relevancia debido al COVID-19 '>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias21}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                5 Ago. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Miguel Bermejo
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Fondos de cobertura </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>Actualmente, en algunas partes del mundo existe un exceso de ahorro a causa, entre otras cosas,
                        de los estímulos fiscales otorgados por diferentes gobiernos y los confinamientos impuestos para
                        afrontar la retadora coyuntura propiciada por la pandemia de COVID-19. </p>

                    <p>Lo anterior, en combinación con otros factores macroeconómicos y la proyección de que este año se
                        recuperarán gran parte de las pérdidas registradas en 2020 (idea alimentada por la reapertura de
                        las economías a nivel global y la demanda rezagada tanto de bienes como de servicios), provocó
                        que, en general, los fondos de cobertura (<em>hedge funds</em>) experimentaran en 2021 uno de
                        sus mejores
                        rendimientos para un primer semestre desde el 2009. Debido a esto, diversos grupos de
                        inversionistas coinciden en que estos fondos son los instrumentos de inversión con los
                        rendimientos potenciales más atractivos para la segunda mitad de 2021. </p>

                    <p>Sin embargo, cabe mencionar que, si bien una proporción importante de los inversores de fondos
                        alternativos y profesionales <em>senior</em> del área de RI (especializados en fondos de
                        cobertura) tiene
                        esta perspectiva y se encuentra satisfecha por los buenos rendimientos, ciertamente dicha
                        proporción es menor a la que compartía esta visión para el 1S21. </p>

                    <p>Si bien, lo anterior significa que el auge que están teniendo estos fondos podría ser temporal,
                        es algo que sólo se podrá confirmar en el mediano plazo; por lo que, mientras tanto, los
                        departamentos de RI de las compañías podrían aprovechar para tener un mayor acercamiento con
                        este tipo de fondos para impulsar la diversificación de su base de inversionistas, ya que el
                        perfil de los inversores es distinto al de aquellos que participan en fondos tradicionales. Por
                        ejemplo, dado que los <em>hedge funds</em> ofrecen mayor flexibilidad y mayor potencial de
                        rendimiento (e
                        inherentemente mayor riesgo), al no contar con ciertas limitaciones de inversión que tienen los
                        fondos tradicionales, es necesario que los inversores estén cualificados, es decir, que cumplan
                        las condiciones legales para ser considerados como tal. </p>

                    <p>Por esta razón, resulta de suma importancia no aminorar los esfuerzos de targeting y mantener
                        actualizada la presentación corporativa de la empresa (es de suma importancia que esta última
                        incluya los aspectos tecnológicos y ASG que actualmente se encuentran en el radar de los
                        inversionistas). En este respecto, existen ciertas consultorías especializadas en la realización
                        de estudios de materialidad, los cuales podrían ser un buen punto de partida para identificar de
                        forma acertada los temas de mayor interés para los inversionistas de una empresa.</p>

                    <p>Referencias Bibliográficas
                        <ul>
                            <li>HFM & AIMA. (2021). Investor Intentions H2 2021. Agosto, 2021. <a
                                href="https://www.aima.org/educate/aima-research/investor-intentions-h2-2021.html"
                                target="_blank">AIMA</a>.
                            </li>
                            <li>KINGSWOOD. (2021). Investment outlook Q3 2021. Agosto, 2021. <a
                                href="https://www.kingswood-group.com/investment-outlook-q3-2021/"
                                target="_blank">KINGSWOOD</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
